/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ublLogo from '../assets/app_logo.png';
import HelmetHeader from '../components/HelmetHeader';
import routeData from '../data/routeData';
import { useLoginMutation } from '../redux/features/auth/authApi';

const { Title } = Typography;
function Login() {
    const [login, { data, isLoading, error: responseError }] = useLoginMutation();
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const [isPassReset, setIsPassReset] = useState(false);
    const [isPrivileged, setIsPrivileged] = useState(false);

    useEffect(() => {
        if (responseError?.data?.message) {
            setErrorMessage(responseError.data.message);
        }
        if (errorMessage) {
            message?.error(errorMessage || 'Something went wrong');
        }
        if (data?.data.access_token && data?.data.payload) {
            const findRoute = routeData.find(
                (item) => item.name === data?.data?.payload.landingPage.label
            );
            message.success(data?.data?.message);
            navigate(findRoute.path);
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, responseError, errorMessage]);

    const onFinish = async (values) => {
        setErrorMessage('');
        login({ ...values, loggedOn: 'Web' });
    };

    return (
        <>
            <HelmetHeader title="Login" />
            {/* <div className="main-login-container" style={{ position: 'relative' }}>
                <Row className="login-content">
                    <Col className="login-left-container" sm={24} xs={24} md={12} lg={12}>
                        <div className="login-left">
                            <div>
                                <img width="100%" src={nagadLogo} alt="HawkEyes Logo" />
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} xs={24} md={12} lg={12}>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <div className="login-text">
                                <h1>{isPassReset ? 'Update Password' : 'SIGN IN'}</h1>
                            </div>

                            <div className="login-input-field">
                                {isPassReset ? (
                                    <>
                                        <Form.Item
                                            name="newPass"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Password!',
                                                },
                                                {
                                                    max: 64,
                                                    message: 'Maximum 64 characters',
                                                },
                                                {
                                                    pattern: isPrivileged
                                                        ? /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){16,64}$/gm
                                                        : /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{12,64})\S$/gm,
                                                    message: `Minimum ${
                                                        isPrivileged ? '16' : '12'
                                                    } and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                style={{ border: 'none' }}
                                                type="password"
                                                placeholder="New Password"
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="retypeNewPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Password!',
                                                },
                                                {
                                                    max: 64,
                                                    message: 'Maximum 64 characters',
                                                },
                                                {
                                                    pattern: isPrivileged
                                                        ? /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){16,64}$/gm
                                                        : /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{12,64})\S$/gm,
                                                    message: `Minimum ${
                                                        isPrivileged ? '16' : '12'
                                                    } and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                style={{ border: 'none' }}
                                                type="password"
                                                placeholder="Retype New Password"
                                                size="large"
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            name="username"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                            className="login-input-username"
                                        >
                                            <Input
                                                autoComplete={false}
                                                // style={{
                                                //     border: '2px solid #15181A',
                                                //     marginBottom: '5px!Important',
                                                //     backgroundColor: '#272B2E',
                                                //     color: 'white',
                                                // }}
                                                size="large"
                                                placeholder="Username"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            autoComplete={false}
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Password!',
                                                },
                                            ]}
                                            className="login-input-username"
                                        >
                                            <Input.Password
                                                type="password"
                                                placeholder="Password"
                                                size="large"
                                                className="login-pass"
                                                // style={{
                                                //     border: '2px solid #15181A',
                                                //     backgroundColor: '#272B2E',
                                                // }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </div>

                            <div className="login-submit">
                                <Form.Item>
                                    <Button
                                        loading={isLoading}
                                        size="large"
                                        type="primary"
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        {isPassReset ? 'UPDATE' : 'LOGIN'}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <p
                    style={{
                        position: 'absolute',
                        bottom: '40px',
                        fontWeight: 600,
                        fontSize: '18px',
                        color: '#fff',
                    }}
                >
                    V_{`${process.env.REACT_APP_VERSION}`}
                </p>
                <p
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        fontWeight: 600,
                        fontSize: '18px',
                        color: '#fff',
                    }}
                >
                    Powered By © HawkEyes Digital Monitoring Ltd. All rights reserved.{' '}
                    <img src={logo} alt="HawkEyes" width="100" />
                </p>
            </div> */}

            <Row>
                <Col span={12} className="login-left-site-container">
                    <div className="login-left-site">
                        <div style={{ textAlign: 'center' }}>
                            <p className="login-semi-title">Nice to see you again</p>
                            <h1 className="login-title">WELCOME BACK</h1>
                            <div
                                style={{
                                    width: '40px',
                                    height: '5px',
                                    background: 'white',
                                    margin: '0 auto',
                                }}
                            />
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="login-form-container">
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                                <img alt="ubl logo" src={ublLogo} width={150} />
                                <Title level={3}>Login</Title>
                            </div>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username!',
                                    },
                                ]}
                            >
                                <Input
                                    className="login-input-field-customize"
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input
                                    className="login-input-field-customize"
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            {/* <Form.Item> */}
                            {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item> */}

                            {/* <a
                                    className="login-form-forgot"
                                    href="https://abc.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Forgot password?
                                </a>
                            </Form.Item> */}

                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Login;
