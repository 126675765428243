/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { Checkbox, Col, Image, Row, Space, Table, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateBacklitDataMutation } from '../../redux/features/backlit/backlitApi';

const { Column, ColumnGroup } = Table;

function ExecutionItem({ data, quarter, queryFunc, page, limit }) {
    const {
        _id,
        town: { name: townName, region, area, territory },
        name: outletName,
        outletcode: outletCode,
        status,
        payoutApplicable,
        modified,
        images,
    } = data;

    const monthsToCheck = ['April', 'May', 'June', 'July', 'August', 'September'];
    const exists = monthsToCheck.some((month) => images.some((item) => item.month === month));

    const defaultValues = payoutApplicable?.map((x, i) => (x.applicable ? i + 1 : '')) || [];

    // update api
    const [updateBacklitData, { isLoading }] = useUpdateBacklitDataMutation();

    const [quarterMonth, setQuarterMonth] = useState([]);
    const [itChange, setItChange] = useState(false);

    const splitQuarter = quarter.split('-');
    const onChange = (checkedValues) => {
        const checkedMonth = checkedValues.map((x) => ({
            applicable: true,
            quarter: `${splitQuarter[1]}0${x}${splitQuarter[0]}`,
            month: x,
        }));
        setQuarterMonth(checkedMonth);
        setItChange(true);
    };

    const submitPayableData = async () => {
        let modifyQuarter = quarterMonth;
        const defaultQuarter = defaultValues?.map((y, i) => ({
            applicable: Boolean(y),
            quarter: `${splitQuarter[1]}0${i + 1}${splitQuarter[0]}`,
            month: i + 1,
        }));

        try {
            if (quarterMonth.length < 3) {
                modifyQuarter = new Array(3).fill('').map((_, i) =>
                    quarterMonth.find((x) => x.month === i + 1)?.month
                        ? quarterMonth.find((x) => x.month === i + 1)
                        : {
                              applicable: false,
                              quarter: `${splitQuarter[1]}0${i + 1}${splitQuarter[0]}`,
                              month: i + 1,
                          }
                );
            }
            const result = await updateBacklitData({
                outletId: _id,
                payoutApplicable: itChange ? modifyQuarter : defaultQuarter,
            }).unwrap();
            message.success('Task Complete');
            queryFunc(page, limit);
        } catch (error) {
            message.error('Something went wrong');
        }
    };

    // user information log
    const { user: userInfo } = useSelector((state) => state.auth);
    const editableUser = !!userInfo?.permission?.find((x) => x.label === 'Backlit Audit Edit')
        ?.label;

    return (
        <div className="execution-item">
            <Row
                gap={[5, 5]}
                justify="space-between"
                style={status === 'Pending' ? { background: '#C50B00' } : {}}
                className={
                    status === 'Pending'
                        ? 'execution-item-error execution-item-card'
                        : 'execution-item-card'
                }
            >
                <Col lg={6} md={12} sm={24}>
                    <p className="ex-item-point">
                        <span style={{ fontWeight: '500' }}>Region: </span>
                        {region}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Area: </span>
                        {area}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Territory: </span>
                        {territory}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Town: </span>
                        {townName}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Outlet Code: </span>
                        {outletCode}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Outlet Name: </span>
                        {outletName}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Status: </span>
                        {status}
                    </p>
                    <p className="ex-item-point">
                        <span className="ex-item-point-b">Submit/Edit Timestamp: </span>
                        {modified ? dayjs(modified).format('DD-MM-YY hh:mm:ss A') : 'N/A'}
                    </p>
                </Col>
                <Col lg={12} md={12} sm={24}>
                    <Space size={[8, 16]}>
                        {images?.map((image, idx) => (
                            <div key={idx}>
                                <p
                                    style={{
                                        fontWeight: '500',
                                        color: status === 'Pending' ? '#fff' : '',
                                    }}
                                >
                                    {image.month}
                                </p>
                                {/* <p style={{ color: status === 'Pending' ? '#fff' : '' }}>
                                Dimension: 1x2x2
                            </p> */}
                                <Image.PreviewGroup
                                    preview={{
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        countRender: (current) =>
                                            (
                                                <span
                                                    style={{ background: 'black', padding: '2px' }}
                                                >
                                                    {image?.urls[current - 1]?.name}
                                                </span>
                                            ) || 'null',
                                    }}
                                >
                                    <Row gutter={[2, 2]}>
                                        {image.urls?.map(({ original, thumb }, i) => (
                                            <Col key={original + i}>
                                                <Image
                                                    width={45}
                                                    preview={{ src: original }}
                                                    src={thumb || ''}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Image.PreviewGroup>
                            </div>
                        ))}
                    </Space>
                </Col>

                <Col lg={6} md={12} sm={24}>
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Checkbox.Group defaultValue={defaultValues} onChange={onChange}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Checkbox
                                    // disabled={!editableUser}
                                    style={{ color: status === 'Pending' ? '#fff' : '' }}
                                    value={1}
                                >
                                    1st Month
                                </Checkbox>
                                <Checkbox
                                    // disabled={!editableUser}
                                    style={{ color: status === 'Pending' ? '#fff' : '' }}
                                    value={2}
                                >
                                    2nd Month
                                </Checkbox>
                                <Checkbox
                                    // disabled={!editableUser}
                                    style={{ color: status === 'Pending' ? '#fff' : '' }}
                                    value={3}
                                >
                                    3rd Month
                                </Checkbox>
                            </div>
                        </Checkbox.Group>
                        {/* {editableUser && exists ? (
                            <Popconfirm
                                disabled={!editableUser}
                                title="Submit Warning!"
                                description="Are you sure to do it?"
                                // eslint-disable-next-line no-underscore-dangle
                                onConfirm={submitPayableData}
                                okText="Yes"
                                cancelText="No"
                                okType="danger"
                                okButtonProps={{
                                    loading: isLoading,
                                }}
                            >
                                <Button title="Submit">
                                    {status === 'Pending' ? 'Submit' : 'Edit'}
                                </Button>
                            </Popconfirm>
                        ) : null} */}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ExecutionItem;
